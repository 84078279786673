import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ReactProps } from "Types";
import AppRoutes from "./AppRoutes";
import useAuthenticate from "./hooks/useAuthenticate";
import useLoadEnvironment from "./hooks/useEnvironment";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Purpose of this wrapper around `<AuthContextProvider>` is only to
// be able to `useAuthenticate()` within scope of `EnvVarsContextProvider`.
function AuthProvider({ children }: ReactProps) {
  const { AuthContextProvider } = useAuthenticate();
  return <AuthContextProvider>{children}</AuthContextProvider>;
}

function RootComponent() {
  const { EnvVarsContextProvider } = useLoadEnvironment();

  const queryClient = new QueryClient();

  return (
    <EnvVarsContextProvider>
      <BrowserRouter>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </EnvVarsContextProvider>
  );
}

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
