import Box from "@amzn/awsui-components-react/polaris/box";
import { ReactProps } from "Types";

interface ValueWithLabelProps extends ReactProps {
  readonly label: String;
}

const ValueWithLabel = ({ label, children }: ValueWithLabelProps) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);
export default ValueWithLabel;
