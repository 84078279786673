import Alert from "@amzn/awsui-components-react/polaris/alert";
import { CfoSfoMappingsResponse } from "Api/useQueryCfoSfoMappings";
import CfoSfoMappingsTable from "components/CfoSfoMappingsTable";
import { OrderType } from "Types/CfoSfoMappings";

const CfoSfoMappingsPageResult = ({ response }: { response: CfoSfoMappingsResponse }) => {
  if (response.orderType === OrderType.NON_IBA) {
    return <Alert>`{response.orderId}` is not an IBA order.</Alert>;
  }

  return <CfoSfoMappingsTable {...response} />;
};
export default CfoSfoMappingsPageResult;
