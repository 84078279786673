import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import ValueWithLabel from "components/ValueWithLabel";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { BusinessEvent, BusinessEventType, EnrollEventData, UnenrollEventData } from "Types/BusinessAuditTypes";

const CardForEnrollEvent = ({ source, eventData: { marketplace, policy } }: BusinessEvent<EnrollEventData>) => (
  <ColumnLayout columns={3}>
    <ValueWithLabel label="Marketplace at enrollment">{marketplace}</ValueWithLabel>
    <ValueWithLabel label="Policy at enrollment">{policy}</ValueWithLabel>
    <ValueWithLabel label="Source">{source}</ValueWithLabel>
  </ColumnLayout>
);

const CardForUnenrollEvent = ({ source, eventData: { optOutReason } }: BusinessEvent<UnenrollEventData>) => (
  <ColumnLayout columns={2}>
    <ValueWithLabel label="Opt out reason">{optOutReason}</ValueWithLabel>
    <ValueWithLabel label="Source">{source}</ValueWithLabel>
  </ColumnLayout>
);

const CardForGenericEvent = ({ source }: BusinessEvent<any>) => (
  <ValueWithLabel label="Source">{source}</ValueWithLabel>
);

const RenderCard = (event: BusinessEvent<any>) => {
  if (event.eventType === BusinessEventType.Enroll) {
    return <CardForEnrollEvent {...event} />;
  } else if (event.eventType === BusinessEventType.Unenroll) {
    return <CardForUnenrollEvent {...event} />;
  }
  return <CardForGenericEvent {...event} />;
};

interface BusinessStatusHistoryProps {
  readonly businessEvents: BusinessEvent<any>[];
}

const sortBusinessEvents = (businessEvents: BusinessEvent<any>[]) => {
  businessEvents.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
};

const BusinessStatusHistory = ({ businessEvents }: BusinessStatusHistoryProps) => {
  sortBusinessEvents(businessEvents);

  const cardHeader = (item: BusinessEvent<any>) => `${item.eventType} - ${item.date}`;

  return (
    <Container header={<Header variant="h2">History</Header>}>
      <Cards
        empty={<b>No events registred for business.</b>}
        cardsPerRow={[{ cards: 1 }]}
        cardDefinition={{
          header: cardHeader,
          sections: [
            {
              content: RenderCard,
            },
          ],
        }}
        items={businessEvents}
      />
    </Container>
  );
};
export default BusinessStatusHistory;
