import Select, { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import React from "react";

const MARKETPLACES = ["United Kingdom", "France", "Germany", "Italy", "Spain"];

const useMarketplacePicker = (initialMarketplace: string | null) => {
  const marketplaceOptions = MARKETPLACES.map((marketplace) => ({ label: marketplace, value: marketplace }));
  const initialMarketplaceOption =
    marketplaceOptions.find((option) => option.label === initialMarketplace) ?? marketplaceOptions[0];

  const [selectedMarketplaceOption, setSelectedMarketplaceOption] =
    React.useState<SelectProps.Option>(initialMarketplaceOption);

  const handleSelectedMarketplaceOptionChanged = ({ detail }: { detail: SelectProps.ChangeDetail }) =>
    setSelectedMarketplaceOption(detail.selectedOption);

  const MarketplacePicker = () => (
    <Select
      selectedOption={selectedMarketplaceOption}
      options={marketplaceOptions}
      onChange={handleSelectedMarketplaceOptionChanged}
    />
  );

  const marketplace = selectedMarketplaceOption.label!!;

  return { marketplace, MarketplacePicker };
};

export default useMarketplacePicker;
