import Table from "@amzn/awsui-components-react/polaris/table";
import Header from "@amzn/awsui-components-react/polaris/header";
import { CfoSfoMapping, CfoSfoMappingItem, OrderType } from "Types/CfoSfoMappings";

interface CfoSfoMappingsTableProps {
  readonly orderId: string;
  readonly orderType: OrderType;
  readonly cfoSfoMappings: CfoSfoMapping[];
}

const grassifyCell = ({ entityId, grassUrl }: CfoSfoMappingItem) => (
  <a href={grassUrl} target={"_blank"} rel="noreferrer">
    {entityId}
  </a>
);

const COLUMNS = {
  cfoId: {
    header: "CFO",
    cell: (row: CfoSfoMapping) => grassifyCell(row.cfo),
  },
  cfoShipmentId: {
    header: "CFO shipment",
    cell: (row: CfoSfoMapping) => grassifyCell(row.cfoShipment),
  },
  sfoId: {
    header: "SFO",
    cell: (row: CfoSfoMapping) => grassifyCell(row.sfo),
  },
  sfoShipmentId: {
    header: "SFO shipment",
    cell: (row: CfoSfoMapping) => grassifyCell(row.sfoShipment),
  },
};

const CFO_MAPPINGS_COLUMNS = [COLUMNS.cfoId, COLUMNS.cfoShipmentId, COLUMNS.sfoId];
const SFO_MAPPING_COLUMNS = [COLUMNS.sfoId, COLUMNS.cfoId, COLUMNS.cfoShipmentId];

const CfoSfoMappingsTable = ({ orderId, orderType, cfoSfoMappings }: CfoSfoMappingsTableProps) => {
  const columns = orderType === OrderType.CFO ? CFO_MAPPINGS_COLUMNS : SFO_MAPPING_COLUMNS;
  const numShipments = cfoSfoMappings.length;
  const headerTitle =
    orderType === OrderType.CFO
      ? `CFO to SFO mappings for CFO with id '${orderId}' (Total shipments: ${numShipments})`
      : `SFO to CFO mappings for SFO with id '${orderId}' (Total shipments: ${numShipments})`;

  return (
    <Table
      header={<Header variant="h2">{headerTitle}</Header>}
      columnDefinitions={columns}
      items={cfoSfoMappings}
      empty={
        <b>
          {orderType} with id `{orderId}` has no shipments.
        </b>
      }
    />
  );
};

export default CfoSfoMappingsTable;
