import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import Form from "@amzn/awsui-components-react/polaris/form";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input, { InputProps } from "@amzn/awsui-components-react/polaris/input";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import React from "react";
import useQueryBusinessAudit from "Api/useQueryBusinessAudit";
import useBusinessAuditSearchParams from "./useBusinessAuditSearchParams";
import BusinessAuditPageResponse from "./businessAuditPageResult";

const BusinessAuditPage = () => {
  const { businessAuditSearchParams, setBusinessAuditSearchParams } = useBusinessAuditSearchParams();

  const [businessId, setBusinessId] = React.useState(businessAuditSearchParams.businessId ?? "");

  const { isFetching, error, data: businessAuditResponse, refetch } = useQueryBusinessAudit(businessId);

  const handleBusinessIdChanged = ({ detail }: { detail: InputProps.ChangeDetail }) => setBusinessId(detail.value);
  const handleLookupBusinessButtonClicked = () => {
    setBusinessAuditSearchParams({ businessId });
    refetch();
  };

  return (
    <IBAConsoleLayout>
      <SpaceBetween direction="vertical" size="xxl">
        <Form
          header={<Header variant="h1">Lookup business</Header>}
          actions={
            <Button variant="primary" loading={isFetching} onClick={handleLookupBusinessButtonClicked}>
              Lookup
            </Button>
          }
          errorText={error}
        >
          <FormField label="Business ID" stretch>
            <Input value={businessId} onChange={handleBusinessIdChanged} autoFocus placeholder="Enter Business id" />
          </FormField>
        </Form>

        {businessAuditResponse && <BusinessAuditPageResponse response={businessAuditResponse} />}
      </SpaceBetween>
    </IBAConsoleLayout>
  );
};
export default BusinessAuditPage;
