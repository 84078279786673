import { Auth } from "aws-amplify";
import { Location } from "history";

export interface UserDetails {
  readonly username: string;
  readonly jwtToken: string;
}

function authConfig(props: IAuthenticationProps) {
  return {
    userPoolId: props.userPoolId,
    userPoolWebClientId: props.userPoolWebClientId,
    oauth: {
      domain: props.cognitoOauthDomain,
      scope: ["openid"],
      redirectSignIn: props.frontendDomain,
      redirectSignOut: props.frontendDomain,
      responseType: "code",
    },
  };
}
export interface IAuthenticationProps {
  readonly userPoolId: string;
  readonly userPoolWebClientId: string;
  readonly cognitoOauthDomain: string;
  readonly authProvider: string;
  readonly frontendDomain: string;
}

const FAILED_REDIRECT_COGNITO_MESSAGE = "Failed to redirect to Cognito";

export class Authentication {
  private authProvider: string;
  private urlLocation: Location;

  constructor(props: IAuthenticationProps, urlLocation: Location) {
    Auth.configure(authConfig(props));
    this.authProvider = props.authProvider;
    this.urlLocation = urlLocation;
  }

  public async getUserDetailsOrLogin(): Promise<UserDetails> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = user.signInUserSession.idToken;
      return {
        jwtToken: idToken.jwtToken,
        username: idToken.payload.identities[0].userId,
      };
    } catch (error) {
      Auth.federatedSignIn({ customProvider: this.authProvider, customState: JSON.stringify(this.urlLocation) });
      throw new Error(`${FAILED_REDIRECT_COGNITO_MESSAGE} ${error}`);
    }
  }
}
