export interface BusinessAuditData {
  readonly businessId: string;
  readonly enrolled: boolean;
  readonly denylisted: boolean;
  readonly businessEvents: BusinessEvent<any>[];
}

export enum BusinessEventType {
  Enroll = "Enroll",
  Unenroll = "Unenroll",
}

export interface BusinessEvent<T> {
  readonly eventType: BusinessEventType;
  readonly date: string;
  readonly source: string;
  readonly eventData: T;
}

export interface EnrollEventData {
  readonly marketplace: string;
  readonly policy: string;
}

export interface UnenrollEventData {
  readonly optOutReason: string;
}
