import { createContext, useContext, useEffect, useState } from "react";
import Loading from "components/Loading";
import { EnvironmentVars, loadEnvironmentVariables } from "environment-utils";
import { ReactProps } from "Types";

const EnvVarsContext = createContext<EnvironmentVars | null>(null);

export function useEnvVarsContext(): EnvironmentVars {
  const envVars = useContext(EnvVarsContext);
  if (!envVars) {
    throw new Error("You must use useEnvVarsContext() inside a EnvVarsContext provider.");
  }
  return envVars;
}

export default function useLoadEnvironment(): { EnvVarsContextProvider: (props: ReactProps) => JSX.Element } {
  const [envVars, setEnvVars] = useState<EnvironmentVars | null>(null);

  useEffect(() => {
    loadEnvironmentVariables().then(setEnvVars);
  }, []);

  const EnvVarsContextProvider = ({ children }: ReactProps) => {
    return (
      <Loading isLoading={!envVars} reason="Loading environment variables..." margin="xxl">
        <EnvVarsContext.Provider value={envVars}>{children}</EnvVarsContext.Provider>
      </Loading>
    );
  };

  return { EnvVarsContextProvider };
}
