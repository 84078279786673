const UNKNOWN_API_ERROR_TEXT = "Unknown error occurred when calling API. Please try again.";
/**
 * Convert error from API call to string.
 *
 * See docs: https://axios-http.com/docs/handling_errors.
 */
export function handleAxiosError<T>(error: any): Promise<T> {
  const specificErrorFromBackend = error?.response?.data?.error;
  return Promise.reject(specificErrorFromBackend ? specificErrorFromBackend : UNKNOWN_API_ERROR_TEXT);
}
