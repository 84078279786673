import useApi from "hooks/useApi";
import { useQuery } from "react-query";
import { CfoSfoMapping, OrderType } from "Types/CfoSfoMappings";
import { handleAxiosError } from "./utils";

interface UseQueryCfoSfoMappingsProps {
  readonly marketplace: string;
  readonly orderId: string;
}

export interface CfoSfoMappingsResponse {
  orderId: string;
  orderType: OrderType;
  cfoSfoMappings: CfoSfoMapping[];
}

const CFO_SFO_MAPPINGS_ENDPOINT = "/cfo-sfo-mappings";

export default function useQueryCfoSfoMappings({ marketplace, orderId }: UseQueryCfoSfoMappingsProps) {
  const api = useApi();

  const fetchCfoSfoMappings: () => Promise<CfoSfoMappingsResponse> = async () => {
    try {
      const requestParams = { orderId, marketplace };
      const { data }: { data: CfoSfoMappingsResponse } = await api.get(CFO_SFO_MAPPINGS_ENDPOINT, {
        params: requestParams,
      });

      return data;
    } catch (e) {
      return handleAxiosError(e);
    }
  };

  const queryKey = ["cfoSfoMappings", orderId, marketplace];
  return useQuery<CfoSfoMappingsResponse, string>(queryKey, fetchCfoSfoMappings, { enabled: false });
}
