import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box, { BoxProps } from "@amzn/awsui-components-react/polaris/box";
import { ReactProps } from "Types";

export interface LoadingProps extends ReactProps {
  readonly reason?: string;
  readonly isLoading: boolean;
  readonly margin?: BoxProps.SpacingSize;
}

export default function Loading(props: LoadingProps): React.ReactElement {
  const { reason, isLoading, children, margin } = props;
  if (isLoading) {
    return (
      <Box margin={margin} textAlign="center">
        <Spinner size="large" />
        {reason && <p>{reason}</p>}
      </Box>
    );
  }
  return <>{children}</>;
}
