import { useSearchParams } from "react-router-dom";

const BUSINESS_ID_SEARCH_PARAM = "businessId";

const useBusinessAuditSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const businessId = searchParams.get(BUSINESS_ID_SEARCH_PARAM);
  const businessAuditSearchParams = { businessId };

  const setBusinessAuditSearchParams = ({ businessId }: { businessId: string }) => {
    const newSearchParams = { [BUSINESS_ID_SEARCH_PARAM]: businessId };
    setSearchParams(newSearchParams, { replace: true });
  };

  return { businessAuditSearchParams, setBusinessAuditSearchParams };
};

export default useBusinessAuditSearchParams;
