import { Hub } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const AUTH_FINISHED_WITH_STATE_EVENT = "customOAuthState";
const AMPLIFY_HUB_AUTH_EVENTS_CHANNEL = "auth";

/**
 * Hook to restore previous URL that disappears when doing authorization when redirecting to Cognito.
 */
const useAuthRecoverUrl = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = Hub.listen(AMPLIFY_HUB_AUTH_EVENTS_CHANNEL, ({ payload: { event, data } }) => {
      // Amplify triggers this event to recover the URL saved in state when redirecting to Cognito.
      if (event === AUTH_FINISHED_WITH_STATE_EVENT) {
        const redirectUrl = JSON.parse(data);
        navigate(redirectUrl);
      }
    });
    return () => Hub.remove(AMPLIFY_HUB_AUTH_EVENTS_CHANNEL, listener);
  }, []);
};

export default useAuthRecoverUrl;
