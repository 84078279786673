import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import SideNavigation, { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { ROUTES } from "AppRoutes";
import { useLocation, useNavigate } from "react-router";
import { ReactProps } from "Types";

const IBAConsoleSideNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPage = location.pathname;

  const handleOnSidebarNavigate = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    event.preventDefault();
    const targetPage = event.detail.href;
    if (targetPage !== currentPage) {
      navigate(targetPage);
    }
  };

  return (
    <SideNavigation
      activeHref={currentPage}
      header={{ text: "IBA Console", href: `/` }}
      onFollow={handleOnSidebarNavigate}
      items={ROUTES.map((route) => ({ type: "link", text: route.name, href: `/${route.page}` }))}
    />
  );
};

const IBAConsoleLayout = ({ children }: ReactProps) => (
  <AppLayout content={children} toolsHide navigation={<IBAConsoleSideNavigation />} />
);

export default IBAConsoleLayout;
