import axios from "axios";

export interface EnvironmentVars {
  readonly API_ENDPOINT: string;
  readonly COGNITO_OAUTH_DOMAIN: string;
  readonly USER_POOL_ID: string;
  readonly USER_POOL_WEB_CLIENT_ID: string;
  readonly AUTH_PROVIDER: string;
  readonly FRONTEND_DOMAIN: string;
  readonly WEBSOCKET_ENDPOINT: string;
}

const ENV_VARS_LOCAL_FILE = "/settings-local.json";
const ENV_VARS_NONLOCAL_FILE = "/settings.json";

function isLocalNpmStart(): boolean {
  return process.env.NODE_ENV === "development";
}

export async function loadEnvironmentVariables(): Promise<EnvironmentVars> {
  const environmentFileName = isLocalNpmStart() ? ENV_VARS_LOCAL_FILE : ENV_VARS_NONLOCAL_FILE;
  const environmentVarsResponse = await axios.get(environmentFileName);
  return environmentVarsResponse.data;
}
