import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, Location } from "react-router";
import { Authentication, IAuthenticationProps, UserDetails } from "authentication";
import Loading from "components/Loading";
import { EnvironmentVars } from "environment-utils";
import useAuthRecoverUrl from "./useAuthRecoverUrl";
import { useEnvVarsContext } from "./useEnvironment";
import { ReactProps } from "Types";

const AuthContext = createContext<UserDetails | null>(null);

export function useAuthContext(): UserDetails {
  const userDetails = useContext(AuthContext);
  if (!userDetails) {
    throw new Error("You must use useAuthContext() inside a AuthContext provider.");
  }
  return userDetails;
}

function envVarsToAuthProps(envVars: EnvironmentVars): IAuthenticationProps {
  return {
    userPoolId: envVars.USER_POOL_ID,
    userPoolWebClientId: envVars.USER_POOL_WEB_CLIENT_ID,
    cognitoOauthDomain: envVars.COGNITO_OAUTH_DOMAIN,
    authProvider: envVars.AUTH_PROVIDER,
    frontendDomain: envVars.FRONTEND_DOMAIN,
  };
}

async function doAuthentication(envVars: EnvironmentVars, urlLocation: Location): Promise<UserDetails> {
  const authentication = new Authentication(envVarsToAuthProps(envVars), urlLocation);

  const authDetails = await authentication.getUserDetailsOrLogin();
  return authDetails;
}

export default function useAuthenticate(): { AuthContextProvider: (props: ReactProps) => JSX.Element } {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  const envVars = useEnvVarsContext();

  const saveUrlForRecover: Location = useLocation();

  useEffect(() => {
    doAuthentication(envVars, saveUrlForRecover).then(setUserDetails);
  }, []);

  useAuthRecoverUrl();

  const AuthContextProvider = ({ children }: ReactProps) => {
    return (
      <Loading isLoading={!userDetails} reason="Checking authentication..." margin="xxl">
        <AuthContext.Provider value={userDetails}>{children}</AuthContext.Provider>
      </Loading>
    );
  };

  return { AuthContextProvider };
}
