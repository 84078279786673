import React from "react";
import { OrderDetails } from "../../Types/OrderInfoTypes/OrderDetails";
import { Grid } from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import { OrderDetailsTable } from "../../components/OrderInfoComponents/IBAOrderDetailsTable";

const OrderDetailPageResult = ({ response }: { response: string }) => {
  const parsedResponse: OrderDetails = JSON.parse(response);
  const ibaOrderEntity =
    parsedResponse.ibaOrderEntityDetails.length > 0 ? parsedResponse.ibaOrderEntityDetails[0] : null;

  return (
    <Grid
      gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 1 }, { colspan: 3 }]}
    >
      <Box variant="awsui-key-label" color="text-label">
        <Header variant="h2">Orders</Header>
        <p>{parsedResponse.orderId}</p>
        <Header variant="h2">Reversals</Header>
        <p>{parsedResponse.reversalIdList}</p>
      </Box>

      <Box>
        <Header variant="h2">Corresponding order</Header>
      </Box>

      <Box variant="awsui-key-label">
        <Header variant="h2">Monitoring status</Header>
        {parsedResponse.orderAuditStatus || ""}
      </Box>

      <Box>
        <Header variant="h2">Shipment status</Header>
      </Box>
      <Box>
        <Header variant="h2">Links</Header>
        {grassifyCell(parsedResponse.grassLink)}
      </Box>

      <Box variant="awsui-key-label">{ibaOrderEntity && <OrderDetailsTable ibaOrderEntity={ibaOrderEntity} />}</Box>
    </Grid>
  );
};
export default OrderDetailPageResult;

const grassifyCell = (grassUrl: string) =>
  grassUrl.length > 0 ? (
    <a href={grassUrl} target={"_blank"} rel="noreferrer">
      Grass
    </a>
  ) : (
    ""
  );
