import useApi from "hooks/useApi";
import { useQuery } from "react-query";
import { BusinessAuditData } from "Types/BusinessAuditTypes";
import { handleAxiosError } from "./utils";

const BUSINESS_AUDIT_ENDPOINT = "/business-audit";

const useQueryBusinessAudit = (businessId: string) => {
  const api = useApi();
  const requestParams = { businessId };

  const fetchBusinessAudit: () => Promise<BusinessAuditData> = async () => {
    try {
      const { data } = await api.get<BusinessAuditData>(BUSINESS_AUDIT_ENDPOINT, {
        params: requestParams,
      });

      return data;
    } catch (e) {
      return handleAxiosError(e);
    }
  };

  return useQuery<BusinessAuditData, string>(["businessAudit", businessId], fetchBusinessAudit, { enabled: false });
};
export default useQueryBusinessAudit;
