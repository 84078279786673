import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { IBAOrderEntityDetails } from "../../Types/OrderInfoTypes/IBAOrderEntityDetails";
import Box from "@amzn/awsui-components-react/polaris/box";

interface OrderTableItem {
  field: string;
  value: string | boolean | number;
}

export const OrderDetailsTable = ({ ibaOrderEntity }: { ibaOrderEntity: IBAOrderEntityDetails }) => {
  const items: OrderTableItem[] = Object.entries(ibaOrderEntity).map(([field, value]) => ({
    field,
    value,
  }));

  return (
    <Table
      header={<Box>Order Details</Box>}
      columnDefinitions={[
        { header: "Field", cell: (item: OrderTableItem) => item.field },
        { header: "Value", cell: (item: { value: string | boolean | number }) => String(item.value) },
      ]}
      items={items}
    />
  );
};
