export enum OrderType {
  SFO = "SFO",
  CFO = "CFO",
  NON_IBA = "NON_IBA",
}

export type CfoSfoMappingItem = {
  entityId: string;
  grassUrl: string;
};

export type CfoSfoMapping = {
  cfo: CfoSfoMappingItem;
  cfoShipment: CfoSfoMappingItem;
  sfo: CfoSfoMappingItem;
  sfoShipment: CfoSfoMappingItem;
};
