import { useSearchParams } from "react-router-dom";

const ORDER_ID_SEARCH_PARAM = "orderId";
const MARKETPLACE_SEARCH_PARAM = "marketplace";

const useCfoSfoMappingSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get(ORDER_ID_SEARCH_PARAM);
  const marketplace = searchParams.get(MARKETPLACE_SEARCH_PARAM);
  const cfoSfoMappingSearchParams = { orderId, marketplace };

  const setCfoSfoMappingSearchParams = ({ orderId, marketplace }: { orderId: string; marketplace: string }) => {
    const newSearchParams = { [ORDER_ID_SEARCH_PARAM]: orderId, [MARKETPLACE_SEARCH_PARAM]: marketplace };
    setSearchParams(newSearchParams, { replace: true });
  };

  return { cfoSfoMappingSearchParams, setCfoSfoMappingSearchParams };
};

export default useCfoSfoMappingSearchParams;
