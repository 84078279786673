import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import BusinessStatusCurrent from "components/BusinessStatusCurrent";
import BusinessStatusHistory from "components/BusinessStatusHistory";
import { BusinessAuditData } from "Types/BusinessAuditTypes";

const BusinessAuditPageResponse = ({ response }: { response: BusinessAuditData }) => {
  const { enrolled, denylisted, businessId, businessEvents } = response;
  const headerTitle = `Business audit for business with id '${businessId}'`;
  return (
    <Container header={<Header>{headerTitle}</Header>}>
      <SpaceBetween direction="vertical" size="l">
        <BusinessStatusCurrent enrolled={enrolled} denylisted={denylisted} />
        <BusinessStatusHistory businessEvents={businessEvents} />
      </SpaceBetween>
    </Container>
  );
};
export default BusinessAuditPageResponse;
