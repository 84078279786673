import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import ValueWithLabel from "components/ValueWithLabel";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

interface BusinessStatusCurrentProps {
  readonly enrolled: boolean;
  readonly denylisted: boolean;
}

const boolToStr = (bool: boolean) => (bool ? "True" : "False");

const BusinessStatusCurrent = ({ enrolled, denylisted }: BusinessStatusCurrentProps) => {
  return (
    <Container header={<Header variant="h2">Current status</Header>}>
      <ColumnLayout columns={2}>
        <ValueWithLabel label="Enrolled">{boolToStr(enrolled)}</ValueWithLabel>
        <ValueWithLabel label="Denylisted">{boolToStr(denylisted)}</ValueWithLabel>
      </ColumnLayout>
    </Container>
  );
};
export default BusinessStatusCurrent;
